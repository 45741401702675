var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Background',{staticClass:"header",style:({
      height: _vm.$vuetify.breakpoint.mobile
        ? _vm.calculateWidthColumn(5)
        : _vm.calculateWidthColumn(6)
    }),attrs:{"backgroundColor":"#f9f9f9","color":"rgba(0,0,0,0.2)"}},[_c('div',[_c('PatternShapes',{staticClass:"pattern-shapes",style:({
          height: _vm.$vuetify.breakpoint.mobile
            ? _vm.calculateWidthColumn(5)
            : _vm.calculateWidthColumn(6)
        })}),_c('MainColumn',[(_vm.page)?_c('Title',{attrs:{"title":_vm.page.title,"target":'default'}}):_vm._e()],1)],1)]),(_vm.childrens ? _vm.childrens.length > 1 : false)?_c('Tabs',{attrs:{"activeTab":_vm.activeTab,"childrens":_vm.childrens,"selectedSlug":'all'},on:{"update:activeTab":function($event){_vm.activeTab=$event},"update:active-tab":function($event){_vm.activeTab=$event}}}):_vm._e(),(_vm.page)?_c('RenderBlocks',{attrs:{"blocks":_vm.page.blocksJSON}}):_vm._e(),_vm._l((_vm.childrens),function(page,i){return _c('RenderBlocks',{key:i,class:_vm.activeTab === i ? 'tab active' : 'tab',attrs:{"blocks":page.blocksJSON}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }