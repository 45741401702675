<template>
  <div>
    <Background
      class="header"
      backgroundColor="#f9f9f9"
      color="rgba(0,0,0,0.2)"
      :style="{
        height: $vuetify.breakpoint.mobile
          ? calculateWidthColumn(5)
          : calculateWidthColumn(6)
      }"
    >
      <div>
        <PatternShapes
          class="pattern-shapes"
          :style="{
            height: $vuetify.breakpoint.mobile
              ? calculateWidthColumn(5)
              : calculateWidthColumn(6)
          }"
        />
        <MainColumn>
          <Title v-if="page" :title="page.title" :target="'default'" />
        </MainColumn>
      </div>
    </Background>
    <Tabs
      v-if="childrens ? childrens.length > 1 : false"
      :activeTab.sync="activeTab"
      :childrens="childrens"
      :selectedSlug="'all'"
    />

    <RenderBlocks v-if="page" :blocks="page.blocksJSON" />
    <RenderBlocks
      v-for="(page, i) in childrens"
      :key="i"
      :blocks="page.blocksJSON"
      :class="activeTab === i ? 'tab active' : 'tab'"
    />
  </div>
</template>

<script>
import RenderBlocks from "@/components/blocks/RenderBlocks";
import Title from "@/components/ui/Title";
import Tabs from "@/components/ui/Tabs";
import Background from "@/components/ui/Background";
import PatternShapes from "@/components/ui/PatternShapes";
import { GET_PAGE } from "@/queries/Pages.js";

export default {
  metaInfo() {
    if (this.seo) {
      return {
        title: this.seo.title,
        meta: [
          {
            name: "description",
            content: this.seo.metaDesc
          },
          {
            name: "keywords",
            content: this.seo.metaKeywords
              ? this.seo.metaKeywords
              : this.defaultMetaKeywords
          },
          {
            name: "author",
            content: this.seo.opengraphSiteName
          },
          {
            property: "og:title",
            content: this.seo.title
          },
          {
            property: "og:type",
            content: this.seo.opengraphType
          },
          {
            property: "og:url",
            content: this.seo.opengraphUrl
          },
          {
            property: "og:description",
            content: this.seo.metaDesc
          },
          {
            property: "og:image",
            content: this.seo.opengraphImage
              ? this.seo.opengraphImage.sourceUrl
              : this.defaultUrlImage
          },
          { name: "robots", content: "index,follow" }
        ]
      };
    }
  },

  components: {
    Background,
    PatternShapes,
    RenderBlocks,
    Tabs,
    Title
  },
  data() {
    return {
      seo: false,
      page: null,
      childrens: null,
      activeTab: 0
    };
  },
  async mounted() {
    this.loadStart();
    this.slug = this.$route.params.slug;

    let query = {
      query: GET_PAGE(this.slug)
    };

    this.data = await this.$apollo.query(query);
    if (this.data.data.pages.nodes.length) {
      this.page = this.data.data.pages.nodes[0];
      this.seo = this.page.seo;
      this.childrens = this.data.data.pages.nodes[0].children.nodes;
      this.loadDone();
    } else {
      this.get404();
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding-top: 50px;
}
.header {
  position: relative;
}
.pattern-shapes {
  overflow: hidden;
}
</style>
